











































import { Component, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';

@Component({
  components: { ErrorAlert }
})
export default class CompensationZipDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;
  triggered: boolean = false;

  year: string = format(Date.now(), 'yyyy');

  get years(): string[] {
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 10;
    const years: string[] = [];

    while (startYear <= currentYear) {
      years.push((startYear++).toString());
    }

    return years.reverse();
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.year = format(Date.now(), 'yyyy');

      return;
    }

    setTimeout(() => {
      this.error = null;
      this.triggered = false;
      this.loading = false;
    }, 200);
  }

  async save() {
    this.error = null;

    this.loading = true;

    try {
      await this.$http.post(`api/commands/conservatorship-management/compensation/create-zip`, { year: parseInt(this.year, 10) });
      this.triggered = true;
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
