
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REVERT_PAYMENT } from '../store';
import { Compensation, RevertCompensation } from '../types';
import { ApiResponse } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';

const Compensation = namespace('compensation');
@Component({
  components: { ErrorAlert }
})
export default class RevertCompensationPayedDialog extends Vue {
  @Prop({ type: Object }) compensation!: Compensation | null;
  @Compensation.Action(REVERT_PAYMENT) revertPayment!: (revertParams: RevertCompensation) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;
  revertParams: RevertCompensation = {
    compensation_id: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.revertParams.compensation_id = '';
      this.error = null;
      this.$emit('update:compensation', null);
    }, 200);
  }

  @Watch('compensation')
  watchCompensationClosed(compensation: Compensation | null) {
    if (!compensation) return;

    this.revertParams.compensation_id = compensation.id;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    this.error = null;

    this.loading = true;

    const { error } = await this.revertPayment(this.revertParams);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
