


































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import DownloadAction from './DownloadAction.vue';
import PayCompensationDialog from './PayCompensationDialog.vue';
import { PaginatableMixin } from '@/views/mixin';
import { Compensation, State } from '@/modules/compensation/types';
import { Pagination } from '@/components/types';
import RevertCompensationMailingDialog from './RevertCompensationMailingDialog.vue';
import RevertCompensationPayedDialog from './RevertCompensationPayedDialog.vue';
import RecalculateCompensationDialog from './RecalculateCompensationDialog.vue';
import MailCompensationDialog from './MailCompensationDialog.vue';
import UpdateCompensationDialog from './UpdateCompensationDialog.vue';
import AddFormSheetDialog from '@/modules/compensation/components/AddFormSheetDialog.vue';
import FormSheetDownloadAction from '@/modules/compensation/components/FormSheetDownloadAction.vue';
import UpdateFormSheetDialog from '@/modules/compensation/components/UpdateFormSheetDialog.vue';
import CompensationZipDialog from '@/modules/compensation/components/CompensationZipDialog.vue';
import AppTable from '@/components/AppTable.vue';
import NotPayedDialog from "@/modules/compensation/components/NotPayedDialog.vue";
import InflationCompensationDialog from "@/modules/compensation/components/InflationCompensationDialog.vue";

@Component({
  components: {
    InflationCompensationDialog,
    NotPayedDialog,
    AppTable,
    CompensationZipDialog,
    UpdateFormSheetDialog,
    FormSheetDownloadAction,
    AddFormSheetDialog,
    UpdateCompensationDialog,
    MailCompensationDialog,
    RevertCompensationPayedDialog,
    RevertCompensationMailingDialog,
    DownloadAction,
    PayCompensationDialog,
    RecalculateCompensationDialog
  }
})
export default class CompensationTable extends PaginatableMixin {
  @Prop({ type: Boolean }) loading?: boolean;
  @Prop({ type: Boolean, default: false }) showPayed!: boolean;
  @Prop({ type: Array }) list!: Compensation[];
  @Prop({ type: Number, default: 10 }) rowsPerPage!: number;
  @Prop({ type: Function, default: () => {} }) setPage!: (page: number) => void;
  @Prop({ type: String }) iSearch?: string;
  @Prop({ type: String }) state?: string;
  @Prop({ type: String }) month?: string;

  pagination: Pagination = {};
  urls: any = {};

  updateCompensation: Compensation | null = null;
  recalculate: Compensation | null = null;
  formSheet: Compensation | null = null;
  updateFormSheet: Compensation | null = null;
  revertPayment: Compensation | null = null;
  revertMailing: Compensation | null = null;
  inflation: Compensation | null = null;

  payCompensation: string | null = null;
  mailCompensation: string | null = null;

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'conservateeName' },
      { align: 'right', text: this.$i18n.t('common.attorneyDocket'), value: 'attorneyDocket' },
      { align: 'right', text: this.$i18n.t('label.invoiceNumber'), value: 'invoiceNumber' },
      { align: 'right', text: this.$i18n.t('label.voucherNumber'), value: 'voucherNumber' },
      { align: 'right', text: this.$i18n.t('common.money'), value: 'amount' },
      { align: 'right', text: this.$i18n.t('label.dateRange'), value: 'end' },
      { align: 'right', text: this.$i18n.t('label.formSheet'), value: null, sortable: false },
      { align: 'right', text: this.$i18n.t('common.actions'), value: 'endTrans', sortable: false }
    ];
  }

  get items() {
    return this.list
      .filter((compensation) => {
        if (!this.showPayed && compensation.state === 'payed') {
          return false;
        }

        return (!this.month || format(compensation.end, 'yyyy-MM') <= this.month) &&
          (!this.state || compensation.state === this.state);
      })
      .map(({ begin, end, payed, conservatee, ...compensation }) => ({
        ...compensation,
        conservatee,
        begin,
        end,
        payed,
        beginTrans: format(begin, 'dd.MM.yyyy'),
        endTrans: format(end, 'dd.MM.yyyy'),
        payedTrans: format(payed, 'dd.MM.yyyy'),
        conservateeName: `${conservatee.surname}, ${conservatee.forename}`,
        rowColor: this.rowColor(compensation.state),
        btnColor: this.btnColor(compensation.state)
      }));
  }

  @Watch('state')
  onStateChange() {
    this.page = 1;
  }

  rowColor(state: State) {
    if (state === 'requireFormSheet') {
      return 'info';
    }

    if (state === 'payed') {
      return 'success';
    }

    if (state === 'mailed') {
      return 'warning';
    }

    return '';
  }

  btnColor(state: State) {
    if (state === 'requireFormSheet') {
      return 'btn-info';
    }

    if (state === 'payed') {
      return 'btn-success';
    }

    if (state === 'mailed') {
      return 'btn-warning';
    }

    return 'btn-info';
  }

  created() {
    this.pagination = {
      itemsPerPage: this.rowsPerPage,
      page: this.page,
      sortBy: ['invoiceNumber'],
      descending: true,
      pageCount: 0
    };
  }
}
