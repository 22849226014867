













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_AVAILABLE, GET_AVAILABLE } from '../store';
import { ApiResponse } from '@/components/types';
import { AvailableDates, FetchAvailableDates } from '@/modules/compensation/types';
import DatePicker from '@/components/form/DatePicker.vue';

const Compensation = namespace('compensation');

@Component({
  components: {
    DatePicker
  }
})
export default class CompensationDatePicker extends Vue {
  @Prop({ type: String, required: true }) start!: string;
  @Prop({ type: String, required: true }) end!: string;
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Prop({ type: String, required: true }) startFieldName!: string;
  @Prop({ type: String, required: true }) endFieldName!: string;
  @Prop({ type: Boolean, default: false }) load!: boolean;

  @Compensation.Action(FETCH_AVAILABLE) fetchAvailable!: (data: FetchAvailableDates) => Promise<ApiResponse>;
  @Compensation.Getter(GET_AVAILABLE) getAvailable!: (conservatorship: string) => AvailableDates;

  get availableDates(): any {
    const allowed = this.getAvailable(this.conservatorship);

    return {
      start: (date: string) => allowed.start.includes(date),
      end: (date: string) => allowed.end.includes(date)
    };
  }

  get startDate(): string {
    return this.start;
  }

  set startDate(date: string) {
    this.$emit('update:start', date);
  }

  get endDate(): string {
    return this.end;
  }

  set endDate(date: string) {
    this.$emit('update:end', date);
  }

  @Watch('load', { immediate: true })
  onLoad(load: boolean) {
    if (!load) return;

    return this.fetchAvailable({ conservatorship: this.conservatorship });
  }
}
