


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UPDATE_COMPENSATION } from '../store';
import DatePicker from '@/components/form/DatePicker.vue';
import { Compensation, UpdateCompensation } from '../types';
import { ApiResponse } from '@/components/types';

const Compensation = namespace('compensation');

@Component({
  components: {
    DatePicker
  }
})
export default class UpdateCompensationDialog extends Vue {
  @Prop({ type: Object }) compensation!: Compensation | null;
  @Compensation.Action(UPDATE_COMPENSATION) updateCompensation!: (params: UpdateCompensation) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;

  updateParams: UpdateCompensation = {
    compensation_id: '',
    voucher_number: '',
    mailed: '',
    payed: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:compensation', null);

      this.updateParams = {
        compensation_id: '',
        voucher_number: '',
        mailed: '',
        payed: ''
      };

      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('compensation')
  watchCompensation(compensation: Compensation | null) {
    if (!compensation) return;

    this.updateParams.compensation_id = compensation.id;
    this.updateParams.voucher_number = compensation.voucherNumber;
    this.updateParams.mailed = compensation.mailed;
    this.updateParams.payed = compensation.payed;

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.dialog = true;
    }, 200);
  }

  async save() {
    this.error = null;

    if (!this.compensation) return {};

    this.loading = true;

    return this.updateCompensation(this.updateParams);
  }
}
