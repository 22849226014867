






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';
import {INFLATION} from '../store';
import DatePicker from '@/components/form/DatePicker.vue';
import DownloadAction from './DownloadAction.vue';
import CompensationDatePicker from './CompensationDatePicker.vue';
import { AdjustInflation, Compensation } from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';

const Compensation = namespace('compensation');

@Component({
  components: {
    CompensationDatePicker,
    DatePicker,
    DownloadAction
  }
})
export default class RecalculateCompensationDialog extends Vue {
  @Prop({ type: Object }) compensation!: Compensation | null;

  @Compensation.Action(INFLATION) inflation!: (data: AdjustInflation) => Promise<ApiResponse>;

  uuid: string = uuid();
  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;
  updated: boolean = false;

  command: AdjustInflation = {
    compensation_id: '',
    from: '',
    to: '',
    amount: 0
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:compensation', null);
      this.command = {
        compensation_id: '',
        from: '',
        to: '',
        amount: 0
      };

      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.updated = false;
    }, 200);
  }

  @Watch('compensation')
  watchCompensation(compensation?: Compensation) {
    if (!compensation) return;

    this.command.compensation_id = compensation.id;

    if (compensation.inflation) {
      this.command.from = format(compensation.inflation.from, 'yyyy-MM-dd');
      this.command.to = format(compensation.inflation.to, 'yyyy-MM-dd');
      this.command.amount = compensation.inflation.amount;
    }

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  pdfChanged(event: any) {
    this.$emit('pdfChanged', event);
  }

  save() {
    this.error = null;

    return this.inflation({ from: this.command.from, to: this.command.to, amount: this.command.amount * 100, compensation_id: this.command.compensation_id });
  }
}
