





























































import { Component, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import CourtSelect from "@/modules/court/components/CourtSelect.vue";

@Component({
  components: {
    CourtSelect,
    ErrorAlert,
    DatePicker
  }
})
export default class MonthViewDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  start = format(Date.now(), 'yyyy-MM-dd');
  end = format(Date.now(), 'yyyy-MM-dd');
  court = '';
  file: string | null = null;

  get download(): string {
    return `unbezahlte-anträge-${this.start}-${this.end}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.start = format(Date.now(), 'yyyy-MM-dd');
      this.end = format(Date.now(), 'yyyy-MM-dd');
      this.court = '';

      return;
    }

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.loading = false;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/compensation/not-payed-pdf`, {
        responseType: 'blob',
        params: { from: this.start, to: this.end, court: this.court }
      });

      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
