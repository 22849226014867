









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { PAY } from '../store';
import { PayCompensation } from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';

const Compensation = namespace('compensation');

@Component({
  components: {
    DatePicker
  }
})
export default class PayCompensationDialog extends Vue {
  @Prop({ type: String }) compensationId!: string | null;

  @Compensation.Action(PAY) pay!: (payCompensation: PayCompensation) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  payCompensation: PayCompensation = {
    compensation_id: '',
    payed: '',
    voucher_number: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.payCompensation.compensation_id = '';
      this.payCompensation.voucher_number = '';
      this.payCompensation.payed = '';
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:compensationId', null);
    }, 200);
  }

  @Watch('compensationId')
  watchCompensationClosed(compensationId: string | null) {
    if (!compensationId) return;

    this.payCompensation.compensation_id = compensationId;
    this.payCompensation.voucher_number = '';
    this.payCompensation.payed = format(new Date(), 'yyyy-MM-dd');

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.pay(this.payCompensation);
  }
}
