




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';
import { POSSIBLE_FIXED_RATES, RECALCULATE } from '../store';
import DatePicker from '@/components/form/DatePicker.vue';
import DownloadAction from './DownloadAction.vue';
import CompensationDatePicker from './CompensationDatePicker.vue';
import {
  Compensation,
  PossibleFixedRates,
  PossibleFixedRatesParams,
  RecalculateCompensation
} from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';

const Compensation = namespace('compensation');

@Component({
  components: {
    CompensationDatePicker,
    DatePicker,
    DownloadAction
  }
})
export default class RecalculateCompensationDialog extends Vue {
  @Prop({ type: Object }) compensation!: Compensation | null;

  @Compensation.Action(RECALCULATE) recalculate!: (data: RecalculateCompensation) => Promise<ApiResponse>;
  @Compensation.Action(POSSIBLE_FIXED_RATES) possibleFixedRates!: (params: PossibleFixedRatesParams) => Promise<ApiResponse<PossibleFixedRates>>;

  uuid: string = uuid();
  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;
  updated: boolean = false;

  showFinancialFixedRate: boolean = false;
  showAssumedFixedRate: boolean = false;
  showClosedFixedRate: boolean = false;

  recalculation: RecalculateCompensation = {
    compensation_id: '',
    begin: '',
    end: '',
    for_heirs: false,
    fixed_rates: {
      financial_fixed_rate: false,
      assumed_fixed_rate: false,
      closed_fixed_rate: false
    }
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:compensation', null);
      this.recalculation = {
        compensation_id: '',
        begin: '',
        end: '',
        for_heirs: false,
        fixed_rates: {
          financial_fixed_rate: false,
          assumed_fixed_rate: false,
          closed_fixed_rate: false
        }
      };

      this.showFinancialFixedRate = false;
      this.showAssumedFixedRate = false;
      this.showClosedFixedRate = false;

      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.updated = false;
    }, 200);
  }

  @Watch('compensation')
  watchCompensation(compensation?: Compensation) {
    if (!compensation) return;

    this.recalculation.compensation_id = compensation.id;
    this.recalculation.begin = format(compensation.begin, 'yyyy-MM-dd');
    this.recalculation.end = format(compensation.end, 'yyyy-MM-dd');
    this.recalculation.for_heirs = compensation.forHeirs;
    this.recalculation.fixed_rates = { ...compensation.fixedRates };

    this.possibleFixedRates(compensation).then(({ content }) => {
      if (!content) return;

      this.showFinancialFixedRate = content.showFinancialFixedRare;
      this.showAssumedFixedRate = content.showAssumedFixedRate;
      this.showClosedFixedRate = content.showClosedFixedRate;
    });

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  pdfChanged(event: any) {
    this.$emit('pdfChanged', event);
  }

  save() {
    this.error = null;

    return this.recalculate(this.recalculation);
  }
}
